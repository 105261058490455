import { Injectable } from '@angular/core';
import { Router, CanActivate, UrlTree } from '@angular/router';
import { HttpAccountmapperService } from '../shared/httpServices/http-accountmapper.service';
import { Observable } from 'rxjs';


@Injectable({providedIn: 'root'})
export class SlaAuthGuard implements CanActivate {

  slaAccessObservable: Observable<boolean | UrlTree>;

  constructor(
    private router: Router,
    private accountmapperService: HttpAccountmapperService) {}

  async canActivate() {
    const accesses =  await this.accountmapperService.getTenantAccesses();
    return accesses.sla
      ? true
      : this.router.parseUrl('/not-authorized');
  }

}
