<!-- service asset rework -->
<form [formGroup]="form" class="mb-2">
    <div class="row">

        <!-- SERVICE -->
        <div class="list-items col-6">
            <div class="d-flex mb-2">
                @if(selectedService?.value) {
                    <label for="serviceSearch"> {{ 'pages.support.log.selectedService' | translate }} </label>
                } @else {
                    <label for="serviceSearch">{{ 'pages.support.log.selectService' | translate }}</label>
                }
            </div>
            @if(!selectedService?.value) {
            <div class="row">
                <!-- service search filter -->
                <div class="col-12">
                    <div class="custom-input">
                        <input type="text" class="form-control" placeholder="{{ 'pages.support.log.filterServices' | translate }}"
                        title="{{ 'pages.assets.dashboard.serviceSearchDesc' | translate }}"
                        name="serviceSearch" formControlName="serviceSearch"
                        id="serviceSearch">
                        <button type="button" class="btn btn-icon delete" attr.aria-label="{{ 'pages.generic.clearSelection' | translate }}"
                            (click)="removeServiceSearch()">
                            <fa-icon [icon]="['fas', 'times']" class="p-1" placement="top" data-toggle="tooltip"
                                data-placement="top"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>

            <!-- service list-->
            <ul class="list-group list-group-flush custom-list mt-2"
                [ngClass]="{'border-danger': submitted && !selectedService?.valid}" infiniteScroll
                [infiniteScrollDistance]="4" [infiniteScrollThrottle]="50" [scrollWindow]="false"
                (scrolled)="loadServices()">
                <div class="no-data" [hidden]="services?.length > 0 || serviceFilter.loading">
                    {{ 'pages.dashboard.noResults' | translate }}
                </div>
                @for(service of services; track service.id) {
                <li class="list-group-item" (click)="onSelectService(service)">{{service.serviceName}}</li>
                }
                @if(serviceFilter.loading) {
                <div class="spinner">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
                    </div>
                </div>
                }
            </ul>
            } @else {
            <div class="custom-label">
                <label class="form-control" type="text"
                    aria-label="Disabled input example">{{selectedService?.value.serviceName}}</label>
                <button type="button" class="btn btn-icon delete" aria-labelledby="closeBtnLabel"
                    (click)="removeSelectedService()">
                    <fa-icon [icon]="['fas', 'times']" class="p-1" placement="top" data-toggle="tooltip"
                        data-placement="top"></fa-icon>
                </button>
            </div>
            }
        </div>

        <!-- ASSET -->
        <div class="list-items col-6">
            <div class="d-flex mb-2">
                @if(selectedAsset?.value) {
                    <label for="assetSearch">{{ 'pages.support.log.selectedAsset' | translate }} </label>
                } @else {
                    <label for="assetSearch">{{ 'pages.support.log.selectAsset' | translate }}</label>
                }
            </div>
            <!-- no asset existing -->
            @if(selectedService?.value && assets.length === 0 && !selectedAsset?.value) {
            <div class="col-10 form-control alert alert-info" role="alert">
                <span class="alert-icon"><span class="visually-hidden">Info</span></span>
                <p>{{'pages.support.log.noExistingAsset' | translate }}</p>
            </div>
            } @else if (selectedAsset?.value) {
            <div class="custom-label">
                <label class="form-control asset" type="text" aria-label="Disabled input example">
                    <span style="width: 25%;">{{selectedAsset?.value.vendor}}</span>
                    <span style="width: 25%;">{{selectedAsset?.value.model}}</span>
                    <span style="width: 25%;">{{selectedAsset?.value.name}}</span>
                    <span style="width: 25%;">{{selectedAsset?.value.serialNumber}}</span>
                </label>
                <button type="button" class="btn btn-icon delete" attr.aria-label="{{ 'pages.generic.clearSelection' | translate }}"
                    (click)="removeSelectedAsset()">
                    <fa-icon [icon]="['fas', 'times']" class="p-1" placement="top" data-toggle="tooltip"
                        data-placement="top"></fa-icon>
                </button>
            </div>
            }

            @else {
            <div class="row">
                <!-- asset search filter -->
                <div class="col-12">
                    <div class="custom-input">
                        <input type="text" class="form-control" placeholder="{{ 'pages.support.log.filterAssets' | translate}}"
                        title="{{ 'pages.assets.dashboard.assetSearchDesc' | translate }}"
                        name="assetSearch" formControlName="assetSearch"
                        id="assetSearch">
                        <button type="button" class="btn btn-icon delete" attr.aria-label="{{ 'pages.generic.clearSelection' | translate }}"
                            (click)="removeAssetSearch()">
                            <fa-icon [icon]="['fas', 'times']" class="p-1" placement="top" data-toggle="tooltip"
                                data-placement="top"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>

            <ul class="list-group list-group-flush custom-list mt-2" infiniteScroll [infiniteScrollDistance]="4"
                [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="loadAssets()">
                <div class=" ms-1 me-1 d-flex align-items-center">
                    <span style="width: 25%;">Vendor</span>
                    <span style="width: 25%;">Model</span>
                    <span style="width: 25%;">Name</span>
                    <span style="width: 25%;">Serial Number</span>
                </div>
                @for(asset of assets; track asset.id) {
                <li class="list-group-item" (click)="onSelectAsset(asset)">
                    <div class=" ms-1 me-1 d-flex align-items-center">
                        <span style="width: 25%;">{{asset.vendor}}</span>
                        <span style="width: 25%;">{{asset.model}}</span>
                        <span style="width: 25%;">{{asset.name}}</span>
                        <span style="width: 25%;">{{asset.serialNumber}}</span>
                    </div>
                </li>
                }
                @if(assetFilter.loading) {
                <div class="spinner">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
                    </div>
                </div>
                }
            </ul>
            }
        </div>
    </div>
</form>