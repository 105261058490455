<div class="services">
  <div class="service-type">

    <div class="section">
      <span [ngClass]="['select-title', isDarkTheme ? 'darkTheme' : 'lightTheme']">{{ 'pages.updates.ocdServices.serviceType' | translate }}</span>

      <!-- all service checkbox-->
      <div class="group" id="all_services">
        <span (click)="goToAnchor(serviceGroupings[0].id)">
          {{ 'pages.updates.ocdServices.allServices' | translate }}
        </span>
        <label for="allServicesCheckbox" class="visually-hidden">
          {{ 'pages.updates.ocdServices.selectAllServices' | translate }}
        </label>
        <input type="checkbox"
          id="allServicesCheckbox"
          class="form-check-input float-end"
          [(ngModel)]=allServices
          (click)="selectedAllServices()">
      </div>

      <!-- ocd service categories checkbox-->
      <div class="group" *ngFor="let group of serviceGroupings">
        <span (click)="goToAnchor(group.id)">{{group.categoryName}}
          <span> ({{group.categories.length}})</span>
        </span>
        <label for="{{group.categoryName}}Checkbox" class="visually-hidden">
          {{ 'pages.generic.select' | translate }} {{ group.categoryName }}
        </label>
        <input type="checkbox" 
          id="{{group.categoryName}}Checkbox"
          class="form-check-input" 
          [(ngModel)]=group.active
          (click)="filterServices(group)">
      </div>

      <!-- ocd service contact button -->
      <button type="button" class="btn btn-secondary mt-5" (click)="openContactForm()">
        {{ 'pages.updates.ocdServices.requestInformation' | translate }}
      </button>

    </div>
    
  </div>
  <div>
    <!-- no service selected -->
    @if (noService) {
    <span class="no-service">{{ 'pages.updates.ocdServices.noServiceSelected' | translate }}</span>
    }

    @if (showContactForm) {
    <!-- Contact form -->
    <div #formSection id="formSection" class="form-section">
      <div class="ms-auto me-2 mt-2">
        <button
          type="button"
          class="btn btn-secondary"
          title="{{ 'pages.updates.training.closeDesc' | translate }}"
          (click)="showContactForm = false">
          {{ 'buttons.action.close' | translate }}
        </button>
      </div>
      
      <iframe [src]="contactFormUrl"
        class="embedded-form"
        title="Contact form"
        height="100%"
        type="text/html">
      </iframe>
    </div>
    } @else {
    <!-- all service's cards-->
    <div *ngFor="let group of serviceGroupings">
      <div *ngIf="group.active">
        <div class="category" [id]="group.id">
          <fa-icon [icon]="['fas', group.icon]" size="xl" data-placement="top"></fa-icon>
          <span class="ms-4">{{group.categoryName}}</span>
        </div>
        <div class="service-grid">
          <div *ngFor="let service of group.categories" class="card highlight"
            style="width: 100%" (click)="openService(service)">
            <figure>
              <img src={{service.img}} alt="orange cyberdefense staff">
            </figure>
            <div class="card-body">
              <h5 [ngClass]="['card-title', isDarkTheme ? 'darkTheme' : 'lightTheme']">
                {{service.title}}
              </h5>
              <p class="card-text fw-bold">{{service.description}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    }
    
  </div>

</div>
