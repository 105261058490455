<div class="row justify-content-center p-5" *ngIf="loading">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
  </div>
</div>
<div class="row" *ngIf="page === 'create' && !loading">
  <div class="col-xl-1 d-none d-lg-block">&nbsp;</div>
  <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 p-3">
    <form [formGroup]="logForm">

      <!-- type -->
      <div class="field">
        <label for="type" class="is-required">
          {{ 'pages.support.dashboard.fieldTitles.type' | translate }}
        </label>
        <app-help message="{{ 'pages.support.log.logtypeDesc' | translate }}"></app-help>
        <select class="form-select" name="type" id="type" aria-describedby="type" formControlName="type"
          [ngClass]="{'border-danger': submitted && !type.valid}">
          <option></option>
          <option *ngFor="let caseType of caseTypes" [value]="caseType.value"
            title="caseType.label | translate | stripHtml">
            {{caseType.value}}</option>
        </select>
      </div>

      <!-- subject -->
      <div class="field">
        <label for="subject" class="is-required">
          {{ 'pages.support.dashboard.fieldTitles.subject' | translate }}
        </label>
        <app-help message="{{ 'pages.support.log.logSubjDesc' | translate }}"></app-help>
        <input type="text" class="form-control" name="subject" id="subject" aria-describedby="subject"
          formControlName="subject" [ngClass]="{'border-danger': submitted && !subject.valid}">
          @if (submitted && logForm.controls.subject.errors?.pattern) {
            <div class="input-error-message">{{ 'pages.support.log.subjectPatternError' | translate }}</div>
          }
      </div>

      <!-- description -->
      <div class="field">
        <label for="desc" class="is-required">
          {{ 'pages.support.dashboard.fieldTitles.description' | translate }}
        </label>
        <app-help message="{{ 'pages.support.log.logDescrDesc' | translate }}"></app-help>
        <textarea class="form-control" id="desc" rows="10" name="desc" formControlName="description"
          [ngClass]="{'border-danger': submitted && !description.valid}"></textarea>
        @if (submitted && logForm.controls.description.errors?.pattern) {
          <div class="input-error-message">{{ 'pages.support.log.descriptionPatternError' | translate }}</div>
        }
      </div>

      <!-- incident -->
      <div class="field" *ngIf="type.value === 'Incident'" formGroupName="incident">
        <label for="priority" class="is-required">
          {{ 'pages.support.dashboard.fieldTitles.priority' | translate }}
        </label>
        <app-help message="{{ 'pages.support.log.logPriorityDesc' | translate }}"></app-help>
        <select class="form-control" name="priority" id="priority" formControlName="priority"
          [ngClass]="{'border-danger': submitted && !priority.valid}">
          <option></option>
          <option value="{{tt}}" *ngFor="let tt of casePriorityCV">{{tt}}</option>
        </select>
        <div class="alert alert-danger mt-2" role="alert" *ngIf="priority.value === '1 - Business Critical'">
          <span class="alert-icon"><span class="visually-hidden"></span></span>
          <p>{{ 'pages.support.dashboard.fieldTitles.priorityOneError' | translate }}
            <br><br>
            <span class='ms-3' *ngFor="let contact of priorityOneErrorContacts">{{contact}}<br></span>
          </p>
        </div>
      </div>

      <!-- change type-->
      <div class="field" *ngIf="type.value === 'Change Request'" formGroupName="changeRequest">
        <label class="is-required" for="changeType">
          {{ 'pages.support.dashboard.fieldTitles.changeType' | translate }}
        </label>
        <app-help message="{{ 'pages.support.log.changeTypeDesc' | translate }}"></app-help>
        <select class="form-control" name="changeType" id="changeType" formControlName="changeType"
          [ngClass]="{'border-danger': submitted && changeType && !changeType.valid}">
          <option *ngFor="let changeType of changeTypes" title="{{ changeType.desc | translate }}"
            value={{changeType.value}}>{{ changeType.label | translate }}
          </option>
        </select>
        <div class="alert alert-danger mt-2" role="alert" *ngIf="type.value === 'Emergency Change'">
          <span class="alert-icon"><span class="visually-hidden"></span></span>
          <p>{{ 'pages.support.dashboard.fieldTitles.priorityOneError' | translate }}
            <br><br>
            <span class='ms-3' *ngFor="let contact of priorityOneErrorContacts">{{contact}}<br></span>
          </p>
        </div>
      </div>

      <!-- preferred date -->
      <div class="field" *ngIf="type.value === 'Change Request' && changetype?.value !== 'Emergency Change'"
        formGroupName="changeRequest">
        <div class="custom-control custom-switch float-start me-3" data-toggle="tooltip" data-placement="top"
          title="{{ 'pages.support.dashboard.enablePreferredDate' | translate }}">
          <input class="form-check-input" type="checkbox" id="showDateTime" ngDefaultControl
            formControlName="preferedDate" />
          <label for="dateTimeInput">
            {{ 'pages.support.dashboard.fieldTitles.preferredDateTime' | translate }}
          </label>
          <app-help message="{{ 'pages.support.dashboard.fieldTitles.preferredDateTimeDesc' | translate }}"></app-help>
        </div>
        <input type="datetime-local" [min]="minDateAllowed" class="form-control" name="dateTime"
          id="dateTimeInput" formControlName="dateTime">
      </div>

      <!-- customer reference -->
      <div class="field">
        <label for="customerReference">
          {{ 'pages.support.dashboard.fieldTitles.customerReference' | translate }}
        </label>
        <app-help message="{{ 'pages.support.log.logRefDesc' | translate }}"></app-help>
        <input type="text" class="form-control" name="customerReference" id="customerReference"
          aria-describedby="customerReference" maxlength="50" formControlName="customerReference">
        @if (submitted && logForm.controls.customerReference.errors?.pattern) {
          <div class="input-error-message">{{ 'pages.support.log.customerReferencePatternError' | translate }}</div>
        }
      </div>
              
      <!-- service asset rework -->
      <app-asset-and-service [(form)]="logForm" [assetRef]="assetRef" [submitted]="submitted"></app-asset-and-service>

      <!-- world watch base reference -->
      <div class="field" *ngIf="worldWatchReference.value">
        <label for="worldWatch">
          {{ 'pages.support.dashboard.fieldTitles.worldWatchReference' | translate }}
        </label>
        <app-help message="{{ 'pages.support.log.worldWatchReferenceDesc' | translate }}"></app-help>
        <input type="text" placeholder={{worldWatchBaseRef}} class="form-control" name="worldWatchReference"
          id="worldWatchReference" aria-describedby="worldWatchReference" maxlength="50"
          formControlName="worldWatchReference">
      </div>

      <!-- contact -->
      <div class="field pt-2">
        <label for="contactSelect">
          {{ 'pages.support.dashboard.fieldTitles.contact' | translate }}
        </label>
        <app-help message="{{ 'pages.support.log.logContactDesc' | translate }}"></app-help>
        <select class="form-select" name="contact" id="contactSelect" formControlName="contactId" [ngClass]="{'border-danger': submitted && !contactId.valid}">
          <option></option>
          <option value="{{tt.id}}" *ngFor="let tt of contacts">{{tt.fullName}}</option>
        </select>
      </div>

      <button id="submit-btn" type="button" class="btn btn-primary float-end" (click)="submit()">{{
        'buttons.action.submit' | translate }}</button>
    </form>
  </div>
  <div class="col-xl-1 d-none d-lg-block">&nbsp;</div>
</div>


<div class="row" *ngIf="page === 'submit'">
  <div class="col-xl-1 d-none d-lg-block">&nbsp;</div>
  <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 p-3">

    <div class="row justify-content-center" *ngIf="!saved">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
      </div>
    </div>

  </div>
  <div class="col-xl-1 d-none d-lg-block">&nbsp;</div>
</div>