export enum XtendedFacet {
  START_TIME = 'start_time',
  END_TIME = 'end_time',
  START_CLOSED_TIME = 'start_closed_time',
  END_CLOSED_TIME = 'end_closed_time',
  SLA_INVESTIGATION = 'sla_investigation',
  SLA_NOTIFICATION = 'sla_notification',
  SEVERITY = 'severity',
  STATUS = 'status',
  WAITING_FOR = 'waiting_for',
  CLOSURE_VERDICT = 'closure_verdict',
  CATEGORY = 'category',
  SUBJECT = 'subject',
  OWNER = 'owner',
  ID = 'id',
  GROUPLEVEL1 = 'groupLevel1',
  GROUPLEVEL2 = 'groupLevel2',
  HOSTNAME = 'hostname',
  HIGHLIGHTED = 'highlighted',
  USERNAME = 'username',
  RULE_NAME = 'rule_name',
  PROCESS_PATH = 'process_path',
  CMD_LINE = 'cmd_line',
  MITRE_TACTIC = 'mitre_tactic',
  MITRE_TECHNIQUE = 'mitre_technique',
  TYPE = 'type',
  CLIENT_TICKET_CREATED = 'client_ticket_created',
  CLIENT_TICKET_ID = 'client_ticket_id',
  //4A3W
  ACTION_LEVEL_1 = 'actionLevel1',
  ACTION_LEVEL_2 = 'actionLevel2',
  ACTOR_LEVEL_1 = 'actorLevel1',
  ACTOR_LEVEL_2 = 'actorLevel2',
  ASSET_LEVEL_1 = 'assetLevel1',
  ATTRIBUTES_LEVEL_1 = 'attributesLevel1',
  WHY_LEVEL_1 = 'whyLevel1',
  WHY_LEVEL_2 = 'whyLevel2',
  WHAT_LEVEL_1 = 'whatLevel1',
  WHO_LEVEL_1 = 'whoLevel1',
}

export type XtendedGroupbyFacet =
  | XtendedFacet.SEVERITY
  | XtendedFacet.OWNER
  | XtendedFacet.STATUS
  | XtendedFacet.CATEGORY
  | XtendedFacet.HOSTNAME
  | XtendedFacet.WAITING_FOR
  | XtendedFacet.CLOSURE_VERDICT
  | XtendedFacet.ACTION_LEVEL_1
  | XtendedFacet.GROUPLEVEL1
  | XtendedFacet.GROUPLEVEL2
  | XtendedFacet.SLA_NOTIFICATION
  | XtendedFacet.USERNAME
  | XtendedFacet.RULE_NAME
  | XtendedFacet.PROCESS_PATH
  | XtendedFacet.CMD_LINE;
