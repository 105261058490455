import { EventEmitter, Injectable } from '@angular/core';
import { CybersocXDRDashboardSearchCriteria } from '../shared/model/searchCriteria';
import { XtendedFacet } from '../shared/model/cybersocxdr/facets';
import { FilterPreset } from '../shared/model/cybersocxdr/filterPreset';
import { XtendedAnalyticsService } from './xtended-analytics.service';
import { DynamicDate, Severity } from '../shared/model/cybersocxdr/facetsValues';
import { ContractService, IncidentDataSource } from '../shared/model/cybersocxdr/incidentDataSourceEnum';
import { ClientInfos, Contract } from '../shared/model/cybersocxdr/clientInfo';

@Injectable({
  providedIn: 'root',
})
export class XtendedFiltersService {
  public searchCriteria: CybersocXDRDashboardSearchCriteria;

  contractChangeEvent = new EventEmitter<Contract | null>();
  private _selectedContractId: number | null = null;
  public get selectedContract(): Contract | null {
    if (
      this._selectedContractId &&
      this.clientInfo?.contracts?.some((c) => c.contractId === this._selectedContractId)
    ) {
      return this.clientInfo.contracts.find((c) => c.contractId === this._selectedContractId) ?? null;
    } else if (this.clientInfo?.contracts?.length > 0) {
      return this.clientInfo.contracts[0];
    }
    return null;
  }

  public set selectedContract(contractId: number | null) {
    if (contractId !== this._selectedContractId) {
      this._selectedContractId = contractId;
      if (this.selectedContract?.incidentDataSource !== this.searchCriteria.incidentDataSource)
        this.onIncidentDataSourceChange(this.selectedContract?.incidentDataSource ?? IncidentDataSource.ELASTIC);
      this.contractChangeEvent.emit(this.selectedContract);
    }
  }

  private _clientInfo: ClientInfos | null = null;
  public set clientInfo(clientInfo: ClientInfos | null) {
    if (!this._clientInfo) {
      this._clientInfo = clientInfo;
      const xtendedContract = this.clientInfo?.contracts.find((c) => c.service === ContractService.XTENDED);
      this.selectedContract = xtendedContract?.contractId ?? null;
    } else {
      this._clientInfo = clientInfo;
    }
  }
  public get clientInfo(): ClientInfos | null {
    return this._clientInfo;
  }

  filterEvent = new EventEmitter<CybersocXDRDashboardSearchCriteria>();

  public currentPreset: string = 'Custom filters';
  public filtersPresets: FilterPreset[] = [
    {
      name: 'Last 6 Months',
      search: { ...new CybersocXDRDashboardSearchCriteria(), createdFrom: DynamicDate.LAST_180D },
    },
    {
      name: 'Incidents of the last month',
      search: { ...new CybersocXDRDashboardSearchCriteria(), createdFrom: DynamicDate.LAST_MONTH },
    },
    {
      name: 'Incidents of this month',
      search: { ...new CybersocXDRDashboardSearchCriteria(), createdFrom: DynamicDate.THIS_MONTH },
    },
    {
      name: 'High & Critical, last 6 months',
      search: {
        ...new CybersocXDRDashboardSearchCriteria(),
        createdFrom: DynamicDate.LAST_180D,
        severitys: [Severity.HIGH, Severity.CRITICAL],
      },
    },
    {
      name: 'High & Critical, last month',
      search: {
        ...new CybersocXDRDashboardSearchCriteria(),
        createdFrom: DynamicDate.LAST_MONTH,
        severitys: [Severity.HIGH, Severity.CRITICAL],
      },
    },
  ];

  facetToFilter: { [key in XtendedFacet]?: keyof CybersocXDRDashboardSearchCriteria } = {
    [XtendedFacet.CATEGORY]: 'category',
    [XtendedFacet.OWNER]: 'owner',
    [XtendedFacet.WAITING_FOR]: 'waitingFor',
    [XtendedFacet.CLOSURE_VERDICT]: 'closureVerdict',
    [XtendedFacet.SEVERITY]: 'severitys',
    [XtendedFacet.STATUS]: 'status',
    [XtendedFacet.SLA_NOTIFICATION]: 'slaNotification',
  };

  constructor(private xtendedAnalyticsService: XtendedAnalyticsService) {
    //Get the search criteria from the session storage
    this.deserializeSearchCriteria();

    if (!this.searchCriteria) {
      //Default preset
      this.searchCriteria = this.filtersPresets[0].search;
    }
    this.checkCurrentPreset();
  }

  checkCurrentPreset() {
    const preset = this.filtersPresets.find(
      (p) =>
        JSON.stringify({
          ...p.search,
          limit: undefined,
          skip: undefined,
          sortBy: undefined,
          incidentDataSource: undefined,
        }) ===
        JSON.stringify({
          ...this.searchCriteria,
          limit: undefined,
          skip: undefined,
          sortBy: undefined,
          incidentDataSource: undefined,
        })
    );
    this.currentPreset = preset ? preset.name : 'Custom filters';
  }

  // called after search is performed
  serializeSeachCriteria() {
    this.checkCurrentPreset();
    sessionStorage.setItem('xtended_soc_incident_form', JSON.stringify(this.searchCriteria));
  }

  /** Deserialize the search criteria data into form data */
  private deserializeSearchCriteria() {
    const storedItem = sessionStorage.getItem('xtended_soc_incident_form');
    if (storedItem) {
      this.searchCriteria = Object.assign(new CybersocXDRDashboardSearchCriteria(), JSON.parse(storedItem));
    }
  }

  applyPreset(preset: FilterPreset) {
    this.searchCriteria = { ...preset.search, incidentDataSource: this.searchCriteria.incidentDataSource };
    this.serializeSeachCriteria();
    this.filterEvent.emit(this.searchCriteria);
    this.xtendedAnalyticsService.trackEvent('apply preset', { preset: preset.name });
  }

  resetFilters() {
    //Default preset
    this.searchCriteria = {
      ...this.filtersPresets[0].search,
      incidentDataSource: this.searchCriteria.incidentDataSource,
    };
    this.serializeSeachCriteria();
    this.xtendedAnalyticsService.trackEvent('reset filters');
  }

  filterIn(facet: XtendedFacet, value: string) {
    const field = this.facetToFilter[facet];
    if (!field) {
      console.error(`Facet ${facet} is not supported for filtering`);
      return;
    }

    (this.searchCriteria[field] as string[]) = [value];
    this.serializeSeachCriteria();
    this.filterEvent.emit(this.searchCriteria);
  }

  filterOut(facet: XtendedFacet, value: string) {
    const field = this.facetToFilter[facet];
    if (!field) {
      console.error(`Facet ${facet} is not supported for filtering`);
      return;
    }

    const index = (this.searchCriteria[field] as string[])?.indexOf(value);
    if (index !== -1) {
      (this.searchCriteria[field] as string[])?.splice(index, 1);
      this.serializeSeachCriteria();
      this.filterEvent.emit(this.searchCriteria);
    }
  }

  onIncidentDataSourceChange(dataSource: IncidentDataSource) {
    this.searchCriteria.incidentDataSource = dataSource;
    this.serializeSeachCriteria();
    this.filterEvent.emit(this.searchCriteria);
    this.xtendedAnalyticsService.trackEvent('incident data source change', { source: dataSource });
  }
}
