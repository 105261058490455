<div >

    <div class="card">
      <div class="card-body">
        <form>
          <div class="row mb-3">
            <!-- Date from -->
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div id="dateFromLabel" class="custom-label">{{ 'pages.generic.dateFrom' | translate }}</div>
              <input type="date" class="form-control" [min]=minDate [max]=maxDate name="dateFrom"
                aria-labelledby="dateFromLabel" [(ngModel)]="dateFrom" #dateFromF="ngModel">
            </div>

            <!-- Date to -->
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div id="dateToLabel" class="custom-label">{{ 'pages.generic.dateTo' | translate }}</div>
              <input type="date" class="form-control" [min]=minDate [max]=maxDate name="dateTo"
                aria-labelledby="dateToLabel" [(ngModel)]="dateTo" #dateToF="ngModel">
            </div>
            <div class="col-xl-8 col-lg-6 col-md-12 col-sm-12 col-12 p-1 text-danger"
              *ngIf="(dateFromF.dirty || dateFromF.touched || dateToF.dirty || dateToF.touched) &&!validateDates()">
              {{ 'pages.reports.itsm.validValues' | translate }}
            </div>
          </div>

          <div class="row mb-3">
            <!-- Type -->
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="types">{{ 'pages.support.dashboard.fieldTitles.type' | translate }}</label>
              <select id="types" name="types" data-style="" class="selectpicker form-control" multiple
                data-max-options="2" (change)="onTypeSelect($event)" [(ngModel)]="selectedTypes">
                <option *ngFor="let type of types" [ngValue]="type"> {{type}}</option>
              </select>
            </div>

            <!-- Priority -->
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="priorities">{{ 'pages.support.dashboard.fieldTitles.priority' | translate }}</label>
              <select id="priorities" name="priorities" data-style="" class="selectpicker form-control" multiple
                data-max-options="2" (change)="onPrioritySelect($event)" [(ngModel)]="selectedPriorities">
                <option *ngFor="let priority of priorities" [ngValue]="priority"> {{priority}}</option>
              </select>
            </div>
          </div>
          <div class="ms-2">
            <button class="btn btn-primary" [disabled]="!validateDates()" (click)="generate();">{{
              'pages.generic.generate' | translate}} </button>
            <button class="btn btn-primary" [disabled]="!validateDates()" (click)="exportReport(content);" data-toggle="tooltip" data-placement="top"
              title="{{ 'pages.support.dashboard.downloadResDesc' | translate }}" *ngIf="!generateReportLoading">
              {{ 'buttons.action.generateReport' | translate }}
            </button>
            <button class="btn btn-primary" type="button" *ngIf="generateReportLoading">
              <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
              {{ 'pages.generic.loading' | translate }}
            </button>
          </div>
        </form>

      </div>
    </div>

</div>


<div class="row mt-5 justify-content-center p-5" *ngIf="!cases && loading">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
  </div>
</div>

<div class="row mt-5" *ngIf="cases && !loading">
  <div class="row">
    <div class="col-xl-11 col-lg-11 col-md-11 col-sm-12 col-12 text-start justify-content-start">

      <div class="row">
        <div class="col-12">
          <h3>{{ 'pages.reports.itsm.casesRevolved' | translate }}</h3>
        </div>
      </div>

      <highcharts-chart *ngIf="toggleShowPie" [Highcharts]="Highcharts" [options]="allCasesDonutChartOptions"
        [(update)]="updateDonutFlag" [oneToOne]=true style="width: 100%; display: block;"></highcharts-chart>

      <highcharts-chart *ngIf="toggleShowGraph" [Highcharts]="Highcharts" [options]="catPieChartOptions"
        style="width: 100%; height: 400px; display: block;"></highcharts-chart>


    </div>
    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12 text-center">
      <ng-template #graphView>{{ 'pages.reports.itsm.showGraph' | translate }}</ng-template>
      <ng-template #pieView>{{ 'pages.reports.itsm.showPie' | translate }}</ng-template>

      <button class="btn" [ngbTooltip]="graphView" (click)="updateGraphView(true, false)"><fa-icon
          [icon]="['fas', 'chart-line']" size="2x"></fa-icon></button>
      <button class="btn" [ngbTooltip]="pieView" (click)="updateGraphView(false, true)"><fa-icon
          [icon]="['fas', 'chart-pie']" size="2x"></fa-icon></button>

    </div>
  </div>
  <div class="col-12">
    <div class="row pt-5">
      <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4">
        <div class="card boxshadow">
          <div class="card-body h-90">
            <div class="text-center">
              <div class="m-r-0 align-self-center"><span class="lstick"></span></div>
              <div class="align-self-center max-width">
                <h2 class="text-muted m-t-10 m-b-0 m-h-2">{{'pages.generic.total' | translate}}<br /><br /></h2>
                <h3 class="orangeText"> {{cases.length}}<br /><br /></h3>
                <i class="fa fa-spinner fa-spin fa-2x p-2" *ngIf="!cases"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4">
        <div class="card boxshadow">
          <div class="card-body h-90">
            <div class="text-center">
              <div class="m-r-0 align-self-center"><span class="lstick"></span></div>
              <div class="align-self-center max-width">
                <h2 class="text-muted m-t-10 m-b-0 m-h-2">{{'menu.reports.percentageSla' | translate}}</h2>
                <h3 class="m-t-0" *ngIf="slaCustomerMet > 0"> {{((slaCustomerMet/slaCustomerTotal)*100) | number :
                  '1.2-2'}}%<br /><br /></h3>
                <h3 class="m-t-0" *ngIf="slaCustomerMet === 0">{{'pages.reports.itsm.no' |
                  translate}}<br />{{'pages.reports.itsm.cases' | translate}}</h3>
                <i class="fa fa-spinner fa-spin fa-2x p-2" *ngIf="!cases"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4">
        <div class="card boxshadow">
          <div class="card-body h-90">
            <div class="text-center">
              <div class="m-r-0 align-self-center"><span class="lstick"></span></div>
              <div class="align-self-center max-width">
                <h2 class="text-muted m-t-10 m-b-0 m-h-2">{{ 'menu.reports.criticalPriority' | translate }}<br /></h2>
                <h3 class="m-t-0" *ngIf="sev1TotalFirstResponse > 0">
                  {{((sev1MetFirstResponse/sev1TotalFirstResponse)*100) | number : '1.2-2'}}%<br /><br /></h3>
                <h3 class="m-t-0" *ngIf="sev1TotalFirstResponse === 0">{{'pages.reports.itsm.no' |
                  translate}}<br />{{'pages.reports.itsm.cases' | translate}}</h3>
                <i class="fa fa-spinner fa-spin fa-2x p-2" *ngIf="!cases"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4">
        <div class="card boxshadow">
          <div class="card-body">
            <div class="text-center">
              <div class="m-r-0 align-self-center"><span class="lstick"></span></div>
              <div class="align-self-center max-width">
                <h2 class="text-muted m-t-10 m-b-0 m-h-2">{{ 'menu.reports.highPriority' | translate }}<br /></h2>
                <h3 class="m-t-0" *ngIf="sev2TotalFirstResponse > 0">
                  {{((sev2MetFirstResponse/sev2TotalFirstResponse)*100) | number : '1.2-2'}}%<br /><br /></h3>
                <h3 class="m-t-0" *ngIf="sev2TotalFirstResponse === 0">{{'pages.reports.itsm.cases' |
                  translate}}{{'pages.reports.itsm.no' | translate}}<br />{{'pages.reports.itsm.cases' | translate}}
                </h3>
                <i class="fa fa-spinner fa-spin fa-2x p-2" *ngIf="!cases"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4">
        <div class="card boxshadow">
          <div class="card-body">
            <div class="text-center">
              <div class="m-r-0 align-self-center"><span class="lstick"></span></div>
              <div class="align-self-center max-width">
                <h2 class="text-muted m-t-10 m-b-0 m-h-2">{{ 'menu.reports.mediumPriority' | translate }}<br /></h2>
                <h3 class="m-t-0" *ngIf="sev3TotalFirstResponse > 0">
                  {{((sev3MetFirstResponse/sev3TotalFirstResponse)*100) | number : '1.2-2'}}%<br /><br /></h3>
                <h3 class="m-t-0" *ngIf="sev3TotalFirstResponse === 0">{{'pages.reports.itsm.cases' |
                  translate}}{{'pages.reports.itsm.no' | translate}}<br />{{'pages.reports.itsm.cases' | translate}}
                </h3>
                <i class="fa fa-spinner fa-spin fa-2x p-2" *ngIf="!cases"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4">
        <div class="card boxshadow">
          <div class="card-body m-h-7">
            <div class="text-center">
              <div class="m-r-0 align-self-center"><span class="lstick"></span></div>
              <div class="align-self-center max-width">
                <h2 class="text-muted m-t-10 m-b-0 m-h-2">{{ 'menu.reports.lowPriority' | translate }}<br /></h2>
                <h3 class="m-t-0" *ngIf="sev4TotalFirstResponse > 0">
                  {{((sev4MetFirstResponse/sev4TotalFirstResponse)*100) | number : '1.2-2'}}%<br /><br /></h3>
                <h3 class="m-t-0" *ngIf="sev4TotalFirstResponse === 0">{{'pages.reports.itsm.cases' |
                  translate}}{{'pages.reports.itsm.no' | translate}}<br />{{'pages.reports.itsm.cases' | translate}}
                </h3>
                <i class="fa fa-spinner fa-spin fa-2x p-2" *ngIf="!cases"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>


<div *ngIf="cases && !loading" style="margin-top: 2rem;">

  <div *ngIf="cases && cases.length > 0">
    <div class="p-0">

      <div class="row m-0 p-2" style="font-weight: bold;">
        <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12 faketableheader">{{
          'pages.support.dashboard.fieldTitles.reference' | translate }}</div>
        <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 d-none d-sm-block faketableheader">{{
          'pages.support.dashboard.fieldTitles.subject' | translate }}</div>
        <div class="col-xl-2 col-lg-2 col-md-4 d-none d-md-block faketableheader">{{
          'pages.support.dashboard.fieldTitles.priority' | translate }}</div>
        <div class="col-xl-2 col-lg-2 d-none d-lg-block faketableheader">{{
          'pages.support.dashboard.fieldTitles.created' | translate }}</div>
        <div class="col-xl-2 d-none d-xl-block faketableheader">{{'pages.support.dashboard.fieldTitles.type' |
          translate}}</div>
        <!-- <div class="col-xl-1 col-lg-2 d-none d-lg-block faketableheader">{{ 'pages.reports.slaMet' | translate }}</div> -->
      </div>

      <div class="row m-0 p-2 click" *ngFor="let case of cases" [routerLink]="['/support/view/' + case.id]"
        routerLinkActive="activeLink">
        <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12 faketablecell">{{case.caseNumber}}</div>
        <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 d-none d-sm-block faketablecell">{{case.subject}}</div>
        <div class="col-xl-2 col-lg-2 col-md-4 d-none d-md-block faketablecell">{{case.priority}}</div>
        <div class="col-xl-2 col-lg-2 d-none d-lg-block faketablecell">{{case.createdDate | date: 'dd/MM/yyyy HH:mm'}}
        </div>
        <div class="col-xl-2 d-none d-xl-block faketablecell">{{case.type}}</div>
        <!-- <div class="col-xl-1 col-lg-2 d-none d-lg-block faketablecell">{{case.madeSla}}</div> -->
      </div>
    </div>
  </div>

  <div *ngIf="!cases || cases.length === 0">
    <div class="p-0">
      <h4>{{'pages.reports.itsm.noIncidentsFound' | translate}}</h4>
    </div>
  </div>
</div>



<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">{{ 'modals.export.title' | translate }}</h5>
  </div>
  <div class="modal-body">
    <div class="form-group col-12 pt-2">
      <label for="contact">{{ 'modals.export.reportName' | translate }}</label>
      <input type="text" class="form-control" name="PDFName" aria-describedby="PDFName" [(ngModel)]="PDFName">
    </div>
    <div class="row">
      <div class="col-6">
        <select class="form-control" name="reportOpt" aria-describedby="reportOpt" [(ngModel)]="reportOption">
          <option value="pdf">PDF</option>
          <option value="csv">CSV</option>
        </select>
      </div>
      <div class="col-6">
        <select class="form-control" name="reportLevel" aria-describedby="reportLevel" [(ngModel)]="pdfAccessLevel">
          <option title="{{ 'pages.generic.ACCESS_INTERNAL_REPORTS' | translate }}" value="0">{{
            'pages.generic.internal' | translate }}</option>
          <option title="{{ 'pages.generic.ACCESS_CONFIDENTIAL_REPORTS' | translate }}" value="1">{{
            'pages.generic.confidential' | translate }}</option>
          <option title="{{ 'pages.generic.ACCESS_RESTRICTED_REPORTS' | translate }}" value="2">{{
            'pages.generic.restricted' | translate }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Close click')">{{ 'pages.generic.close' |
      translate }}</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('save')">{{ 'pages.generic.save' | translate
      }}</button>
  </div>
</ng-template>
