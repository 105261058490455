import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { XtendedFiltersService } from '../../xtendedfilters.service';
import { CybersocxdrService } from 'src/app/shared/services/xtendedsoc/cybersocxdr.service';
import { CybersocXDRDashboardSearchCriteria } from 'src/app/shared/model/searchCriteria';
import { Subscription } from 'rxjs';
import { XtendedAnalyticsService } from '../../xtended-analytics.service';
import { DynamicDate, Severity } from 'src/app/shared/model/cybersocxdr/facetsValues';

@Component({
  selector: 'app-filter-form',
  templateUrl: './filter-form.component.html',
})
export class FilterFormComponent implements OnInit {
  @Output() searchEmitter = new EventEmitter<CybersocXDRDashboardSearchCriteria>(true);

  constructor(
    public globalFilters: XtendedFiltersService,
    private cybersocxdrService: CybersocxdrService,
    private xtendedAnalyticsService: XtendedAnalyticsService
  ) {}

  //Fields options
  severitysOptions = [Severity.LOW, Severity.MEDIUM, Severity.HIGH, Severity.CRITICAL];
  periodOptions = [
    DynamicDate.TODAY,
    DynamicDate.LAST_24H,
    DynamicDate.THIS_WEEK,
    DynamicDate.LAST_7D,
    DynamicDate.THIS_MONTH,
    DynamicDate.LAST_30D,
    DynamicDate.LAST_MONTH,
    DynamicDate.LAST_90D,
    DynamicDate.LAST_180D,
    DynamicDate.THIS_YEAR,
  ];
  repartitions: string[][];
  incidentStatus: string[];
  waitingFor: string[];
  closureVerdict: string[];
  categories: string[];
  owners: string[];
  slaNotification: string[];

  ruleNames: string[];
  mitreTactics: string[];
  mitreTechniques: string[];
  types: string[];

  groupByFiltersName: string[];

  //Used for the select to keep custom if date is selected
  displayCreated: string;

  private globalFilterSubscription = new Subscription();

  async ngOnInit() {
    const {
      repartitions,
      incidentStatus,
      waitingFor,
      closureVerdict,
      categories,
      owners,
      slaNotification,
      ruleNames,
      mitreTactics,
      mitreTechniques,
      types,
    } = await this.cybersocxdrService.getAdvancedFilterValues();

    this.repartitions = repartitions;
    this.incidentStatus = incidentStatus;
    this.waitingFor = waitingFor;
    this.closureVerdict = closureVerdict;
    this.categories = categories;
    this.owners = owners;
    this.slaNotification = slaNotification;
    this.ruleNames = ruleNames;
    this.mitreTactics = mitreTactics;
    this.mitreTechniques = mitreTechniques;
    this.types = types;

    this.displayCreated = this.periodOptions.includes(this.globalFilters.searchCriteria.createdFrom as DynamicDate)
      ? this.globalFilters.searchCriteria.createdFrom
      : 'custom';

    this.groupByFiltersName = await this.cybersocxdrService.getGroupByFilterTypes();

    //Subscribe to actualize fields if needed
    this.globalFilterSubscription = this.globalFilters.filterEvent.subscribe((searchCriteria) => {
      this.displayCreated = this.periodOptions.includes(this.globalFilters.searchCriteria.createdFrom as DynamicDate)
        ? this.globalFilters.searchCriteria.createdFrom
        : 'custom';
    });
  }

  ngOnDestroy() {
    if (this.globalFilterSubscription) this.globalFilterSubscription.unsubscribe();
  }

  isDynamicCreatedDate(): boolean {
    return this.periodOptions.includes(this.globalFilters.searchCriteria.createdFrom as DynamicDate);
  }

  changeCreatedDatePeriode(period: string) {
    this.displayCreated = period;
    this.globalFilters.searchCriteria.createdFrom = period;
    this.globalFilters.searchCriteria.createdTo = undefined;
  }

  checkItem(
    check: boolean,
    name: string,
    field:
      | 'category'
      | 'owner'
      | 'waitingFor'
      | 'closureVerdict'
      | 'severitys'
      | 'status'
      | 'groupLevel1'
      | 'groupLevel2'
      | 'slaNotification'
      | 'mitreTechnique'
      | 'mitreTactic'
      | 'ruleName'
      | 'type'
  ) {
    this.globalFilters.searchCriteria[field] = this.globalFilters.searchCriteria[field].filter((item) => item !== name);
    if (check) this.globalFilters.searchCriteria[field].push(name);
    this.globalFilters.serializeSeachCriteria();
  }

  search() {
    this.globalFilters.serializeSeachCriteria();
    this.searchEmitter.emit(this.globalFilters.searchCriteria);
    this.xtendedAnalyticsService.trackEvent('custom filter', {
      search: JSON.stringify(this.globalFilters.searchCriteria),
    });
  }

  getCustomGroupbyNameKey(index: number): string {
    if (this.groupByFiltersName?.length >= index) return `pages.cybersocxdr.filters.${this.groupByFiltersName[index]}`;
    return '';
  }
}
